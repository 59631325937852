<template>
  <a-modal
    :title="editModalTitle"
    :maskClosable="false"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="editHandleOk"
    @cancel="editHandleCancel"
    width="550px"
    class="edit-user-component"
  >
    <a-form :form="form" @submit="editHandleOk">
      <a-form-item label="用户姓名" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input v-decorator="realNameValidate" autocomplete="off" />
      </a-form-item>
      <a-form-item label="登录账号" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input @blur="dupAccount($event)" v-decorator="usernameValidate" autocomplete="off" />
      </a-form-item>
      <a-form-item label="fs账号" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input v-decorator="fsAccountValidate" autocomplete="off"/>
      </a-form-item>
      <a-form-item label="fs密码" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input v-decorator="fsPasswordValidate" type="password" autocomplete="new-password"/>
      </a-form-item>
      <a-form-item label="联系方式" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input v-decorator="mobileValidate" autocomplete="off" />
      </a-form-item>
      <a-form-item label="营销记录编辑权限" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-radio-group name="enableEdit" v-decorator="enableEditValidate">
          <a-radio :value="true">启用</a-radio>
          <a-radio :value="false">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="状态" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-radio-group name="enabled" v-decorator="enabledValidate">
          <a-radio :value="true">启用</a-radio>
          <a-radio :value="false">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="邮箱" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input v-decorator="emailValidate" autocomplete="off" />
      </a-form-item>
      <a-form-item label="小组名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-select v-decorator="organizationIdValidate">
          <a-select-option
            v-for="(data,key) of allOrganization"
            :key="key"
            :value="data.id"
          >{{ data.name }}</a-select-option>
        </a-select>
      </a-form-item>
<!--      <a-form-item label="接电话(打电话)" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">-->
        <!--        <a-checkbox v-decorator="answerPhoneValidate"></a-checkbox>-->
        <!--        <a-switch v-decorator="answerPhoneValidate" />-->
<!--      </a-form-item>-->
      <a-form-item label="客服类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-radio-group v-decorator="seatTypeValidate" v-model="newEditData.seatType" name="seatType">
          <a-radio value="CALL">
            电话客服
          </a-radio>
          <a-radio value="CUSTOMER">
            在线客服
          </a-radio>
          <a-radio value="SHOW">
            游客
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="角色" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <!--        <role-select :appOrPlatform="2" :defaultRoles="defaultRoles" @selectChange="selectChange"></role-select>-->
        <a-select v-decorator="managerRolesValidate">
          <a-select-option
            v-for="(data,key) of roleList"
            :key="key"
            :value="data.id"
          >{{ data.name }}-{{ data.description }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { addUser, updateUser, dupAccount } from '@/api/manage/user'
import { findAllOrganization } from '@/api/findAll'
import RoleSelect from '@/components/RoleSelect/RoleSelect'
import * as platformrolerapi from '@/api/manage/role'

export default {
  name: 'EditPlatformUserComponent',
  props: {
    showEditModal: Boolean,
    editData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {
    RoleSelect
  },
  watch: {
    showEditModal: function (newVal, oldVal) {
      this.visible = newVal
      this.createForm()
    },
    editData: function (newVal, oldVal) {
      this.createForm()
      this.newEditData = newVal
      this.usernameValidate[1].initialValue = this.newEditData.username
      this.emailValidate[1].initialValue = this.newEditData.email
      this.mobileValidate[1].initialValue = this.newEditData.mobile
      this.realNameValidate[1].initialValue = this.newEditData.realName
      this.enabledValidate[1].initialValue = this.newEditData.enabled
      this.enableEditValidate[1].initialValue = this.newEditData.enableEdit
      this.seatTypeValidate[1].initialValue = this.newEditData.seatType
      this.organizationIdValidate[1].initialValue = this.newEditData.organizationId
      this.fsAccountValidate[1].initialValue = this.newEditData.fsAccount
      this.fsPasswordValidate[1].initialValue = this.newEditData.fsPassword
      if (this.newEditData.managerRoles) {
        this.managerRolesValidate[1].initialValue = this.newEditData.managerRoles[0].id
      }
      // this.form.setFieldsValue({
      //   answerPhone: this.newEditData.answerPhone
      // })
      // 未传递edit对象，则为新增
      if (Object.keys(this.newEditData).length === 0) {
        this.editModalTitle = '新增用户'
      } else {
        this.editModalTitle = '编辑用户'
      }
      // if (this.newEditData.managerRoles) {
      //   this.defaultRoles = this.newEditData.managerRoles.map(obj => obj.id)
      // }
    }
  },
  data () {
    return {
      confirmLoading: false,
      visible: false,
      usernameValidate: ['username', { rules: [{ required: true, message: '登陆账号不能为空！' }] }],
      emailValidate: ['email', { rules: [{ required: true, message: '邮箱不能为空！' }] }],
      mobileValidate: ['mobile', { rules: [{ required: true, message: '联系方式不能为空！' }] }],
      fsAccountValidate: ['fsAccount', { rules: [{ required: false }] }],
      fsPasswordValidate: ['fsPassword', { rules: [{ required: false }] }],
      enabledValidate: ['enabled', { rules: [{ required: true, message: '状态不能为空！' }] }],
      enableEditValidate: ['enableEdit', { rules: [{ required: true, message: '营销记录编辑权限不能为空！' }] }],
      realNameValidate: ['realName', { rules: [{ required: true, message: '用户姓名不能为空！' }] }],
      seatTypeValidate: ['seatType', { initialValue: true, valuePropName: 'checked', rules: [{ required: false }] }],
      organizationIdValidate: ['organizationId', { rules: [{ required: true, message: '小组不能为空！' }] }],
      managerRolesValidate: ['managerRoles', { rules: [{ required: true, message: '请选择角色！' }] }],
      form: null,
      newEditData: {},
      editModalTitle: '新增用户',
      defaultRoles: [],
      changeRoles: [],
      allOrganization: [],
      type: [
        { name: '启用', value: true },
        { name: '禁用', value: false }
      ],
      roleList: []
    }
  },
  beforeCreate () {
    if (this.form === null) {
      this.form = this.$form.createForm(this)
    }
  },
  created () {
    this.createForm()
    this.findAllOrganization()
    this.getRoleList()
    // 未传递edit对象，则为新增
    if (Object.keys(this.editData).length === 0) {
      this.editModalTitle = '新增用户'
      this.passwordValidate = ['password', { rules: [{ required: true, message: '密码不能为空！' }] }]
    } else {
      this.editModalTitle = '编辑用户'
      this.passwordValidate = ['password']
    }
  },
  methods: {
    findAllOrganization () {
      findAllOrganization().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.allOrganization = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    selectChange (value) {
      this.changeRoles = value
    },
    createForm () {
      if (this.form === null) {
        this.form = this.$form.createForm(this)
      }
    },
    editHandleCancel () {
      this.form.resetFields()
      this.defaultRoles = []
      this.$emit('cancel')
    },
    editUserSubmit (values) {
      if (this.newEditData && this.newEditData.id) {
        values.id = this.newEditData.id
        return updateUser(values)
      } else {
        return addUser(values)
      }
    },
    getRoleList () {
      platformrolerapi.getRoleList().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.roleList = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    dupAccount (value) {
      dupAccount({ account: value.target.value }).then(res => {
        if (res.status !== 200) {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
        }
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: '字段名重复'
        })
      })
    },
    editHandleOk () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        this.confirmLoading = true
        // let arr
        // if (this.changeRoles.length === 0) {
        //   arr = this.defaultRoles.map(obj => {
        //     return { id: obj }
        //   })
        // } else {
        //   arr = this.changeRoles.map(obj => {
        //     return { id: obj }
        //   })
        // }
        // values.managerRoles = arr
        values.managerRoles = [{ id: values.managerRoles }]
        if (values.seatType === undefined) {
          values.seatType = false
        }
        if (values.fsAccount === '') {
          values.fsAccount = null
        }
        this.editUserSubmit(values).then(res => {
          if (res.status !== 200) {
            this.$notification.error({
              message: '提示',
              description: res.message
            })
            return
          }
          this.$emit('ok')
          this.$notification.success({
            message: '提示',
            description: res.message
          })
          this.form.resetFields()
        }).catch((e) => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        }).finally(() => {
          this.confirmLoading = false
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .edit-user-component {
    .ant-input-affix-wrapper {
      width: 300px
    }
  }
</style>
