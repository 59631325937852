import { axios } from '@/utils/request'

const basic = '/manage/role'
const api = {
  // 获取角色列表
  getList: basic + '/getList',
  pageList: basic + '/pageList',
  addRole: basic + '/addRole',
  updateRole: basic + '/updateRole',
  deleteRole: basic + '/deleteRole'
}

export function getRoleList (parameter) {
  return axios({
    url: api.getList,
    method: 'get',
    params: parameter
  })
}

export function pageList (parameter) {
  return axios({
    url: api.pageList,
    method: 'get',
    params: parameter
  })
}

export function addRole (parameter) {
  return axios({
    url: api.addRole,
    method: 'post',
    data: parameter
  })
}

export function updateRole (parameter) {
  return axios({
    url: api.updateRole,
    method: 'post',
    data: parameter
  })
}

export function deleteRole (parameter) {
  return axios({
    url: api.deleteRole,
    method: 'post',
    data: parameter
  })
}
