<template>
  <div class="role-select">
    <a-select mode="multiple" v-model="defaultVal" @change="selectChange" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body">
      <a-select-option v-for="item in data" :key="item.id">{{ item.name + ' - ' + item.description }}</a-select-option>
    </a-select>
  </div>
</template>

<script>
import * as rolerapi from '@/api/business/role'
import * as platformrolerapi from '@/api/manage/role'
export default {
  name: 'RoleSelect',
  props: {
    defaultRoles: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 1、app角色。2、平台角色
    appOrPlatform: {
      type: Number,
      default: function () {
        return 1
      }
    }
  },
  data () {
    return {
      rolerapi,
      platformrolerapi,
      data: [],
      defaultVal: [],
      type: 1
    }
  },
  methods: {
    selectChange (value) {
      this.$emit('selectChange', value)
    },
    getRoleList () {
      const api = this.appOrPlatform === 2 ? platformrolerapi : rolerapi
      api.getRoleList().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.data = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    }
  },
  created () {
    this.getRoleList()
    this.defaultVal = this.defaultRoles
  },
  watch: {
    defaultRoles: function (newVal, oldVal) {
      this.defaultVal = newVal
    }
  }
}
</script>

<style lang="less">
  .role-select {

  }
</style>
