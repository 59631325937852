import { axios } from '@/utils/request'
const basic = '/business/role'
const api = {
  // 获取角色列表
  getList: basic + '/getList'
}

export function getRoleList(parameter) {
  return axios({
    url: api.getList,
    method: 'get',
    params: parameter
  })
}
