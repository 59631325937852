<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="用户姓名">
              <a-input v-model="queryParam.keyword" placeholder="请输入关键字查询"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="小组名称">
              <a-select v-model="queryParam.organizationId">
                <a-select-option
                  v-for="(data,key) of allOrganization"
                  :key="key"
                  :value="data.id"
                >{{ data.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.enabled">
                <a-select-option :value="2">全部</a-select-option>
                <a-select-option :value="0">启用</a-select-option>
                <a-select-option :value="1">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="角色名称">
              <a-select v-model="queryParam.roleId">
                <a-select-option
                  v-for="(data,key) of roleList"
                  :key="key"
                  :value="data.id"
                >{{ data.name }}-{{ data.description }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :offset="8" :md="8" :sm="24" class="btnBox">
            <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
          </a-col>
          <a-col :md="24" :sm="24">
            <span>
              <a-button type="primary" icon="plus" @click="addUserClick">添加用户</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table
      class="table"
      :columns="columns"
      :dataSource="data"
      rowKey="id"
      :loading="loading"
      :pagination="pagination"
      @change="pageChange">
      <span slot="managerRoles" slot-scope="text">
        {{ text[0]===undefined?'无':text[0].description }}
      </span>
      <span slot="enabled" slot-scope="text">
        {{ text===true?'启用':'禁用' }}
      </span>
      <span slot="action" slot-scope="text">
        <a-button type="primary" size="small" @click="updUserClick(text)">编辑</a-button>
        <a-divider type="vertical" />
        <a-button type="primary" v-if="text.enabled===false" size="small" @click="switches(text)">启用</a-button>
        <a-button type="danger" v-if="text.enabled===true" size="small" @click="switches(text)">禁用</a-button>
        <a-divider type="vertical" />
        <a-popconfirm title="确认重置？" okText="是" cancelText="否" @confirm="resetPwd(text)">
          <a-button type="primary" size="small">重置密码</a-button>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm title="确认删除？" okText="是" cancelText="否" @confirm="delOk(text.id)">
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <edit-platform-user-component :showEditModal="showEditModal" :editData="editData" @cancel="cancelHandle" @ok="editHandle"></edit-platform-user-component>
  </a-card>
</template>

<script>
import * as userapi from '@/api/manage/user'
import EditPlatformUserComponent from './children/EditPlatformUserComponent'
import { findAllOrganization } from '@/api/findAll'
import * as platformrolerapi from '@/api/manage/role'
import { mapGetters } from 'vuex'

export default {
  name: 'PlatformUserManageView',
  components: {
    EditPlatformUserComponent
  },
  data () {
    return {
      showEditModal: false,
      editData: {},
      pagination: {
        current: 1,
        total: 0,
        showTotal: function (total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: [ '10', '30', '50' ]
      },
      loading: false,
      userapi,
      // 查询参数
      queryParam: {
        page: 0,
        size: 10,
        keyword: null,
        organizationId: null,
        enabled: 2,
        roleId: null
      },
      allOrganization: [],
      roleList: [],
      data: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '用户姓名',
          dataIndex: 'realName'
        },
        {
          title: '登陆账号',
          dataIndex: 'username'
        },
        {
          title: 'FS账号',
          dataIndex: 'fsAccount'
        },
        {
          title: '联系电话',
          dataIndex: 'mobile'
        },
        {
          title: '小组名称',
          dataIndex: 'organizationName'
        },
        {
          title: '角色名称',
          dataIndex: 'managerRoles',
          scopedSlots: { customRender: 'managerRoles' }
        },
        {
          title: '状态',
          dataIndex: 'enabled',
          scopedSlots: { customRender: 'enabled' }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created () {
    this.query()
    this.selectList()
    this.getRoleList()
  },
  methods: {
    selectList () {
      findAllOrganization().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.allOrganization = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    getRoleList () {
      platformrolerapi.getRoleList().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.roleList = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    delOk (id) {
      if (this.userInfo.id === id) {
        this.$notification.error({
          message: '提示',
          description: '请勿删除当前登录用户'
        })
        return
      }
      userapi.deleteUser({ id: id }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        if (this.data.length === 1 && this.pagination.total > 1) {
          this.queryParam.page -= 1
          this.pagination.current -= 1
        } else if (this.data.length === 1 && this.pagination.total === 1) {
          this.queryParam.page = 0
          this.pagination.current = 1
        }
        this.query()
      })
    },
    cancelHandle () {
      this.showEditModal = false
      this.editData = {}
    },
    editHandle () {
      this.cancelHandle()
      this.query()
    },
    updUserClick (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.showEditModal = true
    },
    addUserClick () {
      this.showEditModal = true
    },
    switches (text) {
      const data = { ...text }
      data.enabled = !data.enabled
      data.managerRoles = [{ id: data.managerRoles[0].id }]
      delete data.jobNumber
      delete data.organizationName
      delete data.createTime
      userapi.updateUser(data).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.query()
        this.$notification.success({
          message: '提示',
          description: res.message
        })
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    pageChange (pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick () {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    query () {
      this.loading = true
      userapi.findUserList(this.queryParam).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.data = res.body.data
        this.pagination.total = res.body.total
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.loading = false
      })
    },
    clearClick () {
      this.queryParam = this.$options.data().queryParam
    },
    resetPwd (text) {
      if (this.userInfo.id === text.id) {
        this.$notification.error({
          message: '提示',
          description: '请勿重置当前登录用户密码'
        })
        return
      }
      userapi.resetPwd({ id: text.id }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        this.query()
      })
    }
  }
}
</script>
<style scoped>
  .table{
    margin-top: 30px;
  }
  .btnBox button{
    margin: 0 30px;
  }
</style>
